import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
import { PDFDocument, rgb } from "pdf-lib"

import {
  onStartLoading,
  onFetchExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  getOrderDetails,
  onLoadBarcodePDF,
  clearBarcodePDF,
  getBarcodePDF,
  updateOrderStatus,
  clearOrderUpdate,
  updateOrderStatusByAccount,
  updateOrderStatusAndExtentionDate,
  getBarcodesPDFList,
  clearBarcodesPDFList,
  clearOrderIdsForPrint,
  onUpdateOrderStation,
  clearUpdateOrderStation,
  onTransferOrder,
  clearTransferOrder,
} from "../../../actions"

import "./style.scss"
import OrdersList from "./OrdersList"
import OrderHistoryModal from "../../common/OrderHistoryModal"
import { getFormatedDate } from "../../../utils"
import ExternalNumbersModal from "../../common/ExternalNumbersModal"
import PDFReaderModal from "../../common/PDFReaderModal"
import YesNoModal from "../../common/YesNoModal"
import OrderDelayModal from "../../common/OrderDelayModal"
import NotificationModal from "../../common/NotificationModal"
import ChangeStationModal from "../../common/ChangeStationModal"
import TransferOrderModal from "../../common/TransferOrderModal"

class OrdersListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderExternalNumbers: null,
      isShowHistory: false,
      isShowExternalNumbers: false,
      isShowPDF: false,
      packageNumber: null,
      showCancelOrderModal: false,
      showOrderDelayModal: false,
      selectedOrder: null,
      currentOrderInLockerDate: null,
      isShowChangeStation: false,
      isShowTransferOrder: false,
    }
  }

  componentDidMount() {
    const { authenticated, history, userData } = this.props
    if (!authenticated) {
      history.push("/")
    }

    this.props.onStartLoading()
    this.props.fetchOrderStatuses()
    this.props.onFetchExistOrders()
  }

  componentDidUpdate() {
    const { authenticated, history, barcodePDFList } = this.props
    if (!authenticated) {
      history.push("/")
    }
    if (this.props.orderStatusUpdateSuccess) {
      this.props.onFetchExistOrders()
      this.props.clearOrderUpdate()
    }
    if (barcodePDFList.length > 0) {
      this.printAllPDFs(barcodePDFList)
    }
  }

  printAllPDFs = async (pdfList) => {
    const mergedPdf = await PDFDocument.create()

    for (const pdf of pdfList) {
      const { fileContents } = pdf
      const base64Data = fileContents
      const uint8Array = this.base64ToArrayBuffer(base64Data)
      const existingPdf = await PDFDocument.load(uint8Array)

      const copiedPages = await mergedPdf.copyPages(
        existingPdf,
        existingPdf.getPageIndices()
      )
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page)
      })
    }

    const mergedPdfBytes = await mergedPdf.save()
    const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" })
    const mergedUrl = URL.createObjectURL(mergedBlob)

    const iframe = document.createElement("iframe")
    iframe.style.display = "none"
    iframe.src = mergedUrl

    document.body.appendChild(iframe)

    iframe.onload = () => {
      iframe.contentWindow.print()
    }
    this.props.clearBarcodesPDFList()
    this.props.clearOrderIdsForPrint()
  }

  base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }

  refreshTable() {
    this.props.onFetchExistOrders()
  }

  async onClickOrderHistory(order) {
    const { orderList } = this.props
    console.log(order)
    const currentOrder = orderList.filter(
      (o) => o.orderNumber === order.original.orderNumber
    )[0]

    await this.props.getOrderDetails(currentOrder.id)

    this.setState({ isShowHistory: true })
  }

  async onListIconClick(order) {
    const currentNumbers = order.original.externalOrderNumber.split(",")
    this.setState({ orderExternalNumbers: currentNumbers })
    this.setState({ isShowExternalNumbers: true })
  }

  async onPDFClick(order) {
    this.setState({
      isShowPDF: true,
      packageNumber: order.original.packageNumber,
    })

    this.props.onLoadBarcodePDF()
    this.props.getBarcodePDF(order.original.packageNumber)
  }

  async onCancelOrderClick(order) {
    this.setState({
      showCancelOrderModal: true,
      selectedOrder: order,
    })
  }
  async onChangeStationClick(order) {
    this.setState({
      isShowChangeStation: true,
      selectedOrder: order,
    })
  }

  async onTransferOrderClick(order) {
    this.setState({
      isShowTransferOrder: true,
      selectedOrder: order,
    })
  }

  confirmCancelOrder() {
    this.props.updateOrderStatus(this.state.selectedOrder.id, 6)
    this.setState({ showCancelOrderModal: false })
  }

  async extendPackageClick(order) {
    const currentOrder = this.props.orderList.find((o) => o.id === order.id)
    this.setState({
      showOrderDelayModal: true,
      selectedOrder: order,
      currentOrderInLockerDate: currentOrder.inLockerExtentionDate,
    })
  }

  updateInLockerDate(selectedDate) {
    const { selectedOrder } = this.state
    const dateWithTime = new Date(selectedDate)
    dateWithTime.setHours(23)
    dateWithTime.setMinutes(0)
    const unixTimestamp = Math.floor(dateWithTime.getTime() / 1000)
    this.props.updateOrderStatusByAccount(selectedOrder.id, 4, unixTimestamp)
  }

  onCloseMsg() {
    this.props.clearOrderUpdate()
    this.refreshTable()
    this.setState({
      showOrderDelayModal: false,
    })
  }

  printAllSelectedBarcodes() {
    const { packageNumbersForPrint } = this.props
    if (packageNumbersForPrint.length > 0) {
      this.props.getBarcodesPDFList(packageNumbersForPrint)
    }
  }

  onCloseorderStationUpdatedMsg() {
    this.props.clearUpdateOrderStation()
    this.setState({ isShowChangeStation: false })
  }

  onCloseTransferOrderMsg() {
    this.props.clearTransferOrder()
    this.setState({ isShowTransferOrder: false })
  }

  render() {
    const { isLoading, orderDetails, ordersStatusesArr, file } = this.props

    if (isLoading) {
      return (
        <div>
          <Title text="רשימת הזמנות" />
          <Loader show={isLoading} />
        </div>
      )
    }

    return (
      <div>
        <Title text="רשימת הזמנות" />
        {ordersStatusesArr && ordersStatusesArr.length > 0 ? (
          <OrdersList
            onHistoryClick={(order) => this.onClickOrderHistory(order)}
            onListIconClick={(order) => this.onListIconClick(order)}
            onPDFClick={(order) => this.onPDFClick(order)}
            onCancelOrderClick={(order) => this.onCancelOrderClick(order)}
            refreshTable={() => this.refreshTable()}
            extendPackageClick={(order) => this.extendPackageClick(order)}
            printAllSelectedBarcodes={() => this.printAllSelectedBarcodes()}
            onChangeStationClick={(order) => this.onChangeStationClick(order)}
            onTransferOrderClick={(order) => this.onTransferOrderClick(order)}
          />
        ) : (
          "Loading..."
        )}

        <OrderHistoryModal
          isShow={this.state.isShowHistory}
          order={orderDetails}
          setViewHistory={() => this.setState({ isShowHistory: false })}
          statuses={ordersStatusesArr}
          getFormatedDate={getFormatedDate}
        />
        <ExternalNumbersModal
          show={this.state.isShowExternalNumbers}
          numbers={this.state.orderExternalNumbers}
          onOkClick={() => this.setState({ isShowExternalNumbers: false })}
        />
        <PDFReaderModal
          show={this.state.isShowPDF && file}
          file={file}
          onOkClick={() => {
            this.props.clearBarcodePDF()
            this.setState({ isShowPDF: false })
          }}
        />
        <OrderDelayModal
          key={this.state.selectedOrder}
          isOpen={this.state.showOrderDelayModal}
          isClose={() => this.setState({ showOrderDelayModal: false })}
          submitDate={(selectedDate) => this.updateInLockerDate(selectedDate)}
          inLockerDate={this.state.currentOrderInLockerDate}
        />
        {this.state.isShowChangeStation ? (
          <ChangeStationModal
            show={this.state.isShowChangeStation}
            stations={this.props.stationsList}
            orderId={this.state.selectedOrder?.id}
            onCloseClick={() => this.setState({ isShowChangeStation: false })}
          />
        ) : null}

        {this.state.isShowTransferOrder ? (
          <TransferOrderModal
            stations={this.props.stationsList}
            orderId={this.state.selectedOrder?.id}
            onCloseClick={() => this.setState({ isShowTransferOrder: false })}
          />
        ) : null}

        <YesNoModal
          show={this.state.showCancelOrderModal}
          title={"מחיקת הזמנה"}
          text={"?האם אתה בטוח שברצונך לבטל הזמנה זו"}
          onYesClick={() => this.confirmCancelOrder()}
          onNoClick={() => this.setState({ showCancelOrderModal: false })}
        />
        <NotificationModal
          show={this.props.orderStatusUpdateByAccountSuccess}
          title={" עדכון הזמנה"}
          text={"הזמנה עודכנה בהצלחה"}
          onOkClick={() => this.onCloseMsg()}
        />
        <NotificationModal
          extraClass="msg-error"
          show={this.props.orderStatusUpdateByAccountFail}
          title={" עדכון הזמנה"}
          text={"עדכון הזמנה נכשל"}
          onOkClick={() => this.onCloseMsg()}
        />
        <NotificationModal
          show={this.props.orderStationUpdatedSuccess}
          type={"success"}
          title={"עדכון עמדה"}
          text={"עמדה עודכנה בהצלחה"}
          onOkClick={() => this.onCloseorderStationUpdatedMsg()}
        />
        <NotificationModal
          show={this.props.orderStationUpdatedFail}
          type={"error"}
          title={"עדכון עמדה"}
          text={"עדכון עמדה נכשל"}
          onOkClick={() => this.onCloseorderStationUpdatedMsg()}
        />
        <NotificationModal
          extraClass="success"
          show={this.props.transferOrderSuccess}
          text={"בקשה להעברת הזמנה לעמדה אחרת התקבלה בהצלחה"}
          onOkClick={() => this.onCloseTransferOrderMsg()}
        />
        <NotificationModal
          type={"error"}
          show={this.props.transferOrderFail}
          text={" .שגיא, בקשה לא התקבלה "}
          onOkClick={() => this.onCloseTransferOrderMsg()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  users,
  orders,
  orderStatuses,
  barcode,
  station,
}) => {
  const { authenticated, userData } = users
  const {
    isLoading,
    orderList,
    orderDetails,
    selectedOrder,
    orderStatusUpdateSuccess,
    orderStatusUpdateByAccountSuccess,
    orderStatusUpdateByAccountFail,
    orderStationUpdatedSuccess,
    orderStationUpdatedFail,
    transferOrderSuccess,
    transferOrderFail,
  } = orders
  const { ordersStatusesArr } = orderStatuses
  const { file, packageNumbersForPrint, barcodePDFList } = barcode
  const { stationsList, selectedStationId } = station

  return {
    authenticated,
    isLoading,
    orderList,
    userData,
    orderDetails,
    ordersStatusesArr,
    selectedOrder,
    file,
    orderStatusUpdateSuccess,
    orderStatusUpdateByAccountSuccess,
    orderStatusUpdateByAccountFail,
    packageNumbersForPrint,
    barcodePDFList,
    stationsList,
    orderStationUpdatedSuccess,
    orderStationUpdatedFail,
    transferOrderSuccess,
    transferOrderFail,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onFetchExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  getOrderDetails,
  onLoadBarcodePDF,
  clearBarcodePDF,
  getBarcodePDF,
  updateOrderStatus,
  updateOrderStatusByAccount,
  clearOrderUpdate,
  updateOrderStatusAndExtentionDate,
  getBarcodesPDFList,
  clearBarcodesPDFList,
  clearOrderIdsForPrint,
  onUpdateOrderStation,
  clearUpdateOrderStation,
  onTransferOrder,
  clearTransferOrder,
})(OrdersListScreen)
