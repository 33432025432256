import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Title from "../../common/Title"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import Message from "../../common/Message"
import {
  onUserSignInRequest,
  onUserSignInClick,
  onUserSignOut,
  closeMessage,
  fetchAllStations,
} from "../../../actions"
import "./style.scss"

const LoginScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { authenticated, isLoading, showMessage, userData } = useSelector(
    (state) => state.users
  )

  const INIT_STATE = {
    name: "",
    passw: "",
  }

  const [state, setState] = useState(INIT_STATE)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (authenticated) {
      dispatch(onUserSignOut())
    }
    setState(INIT_STATE)
  }, [])

  useEffect(() => {
    if (authenticated) {
      dispatch(fetchAllStations())
      if (userData?.externalMiniBoTypeId == "3") {
        history.push("L2LOrdersListScreen")
      } else {
        history.push("OrdersListScreen")
      }
    }
  }, [authenticated, history])

  const setValue = (inputName, value) => {
    setState((prevState) => ({
      ...prevState,
      [inputName]: value,
    }))
  }

  const validateForm = () => {
    const newErrors = {}
    if (!state.name.trim()) {
      newErrors.name = "שם משתמש נדרש"
    }
    if (!state.passw.trim()) {
      newErrors.passw = "סיסמא נדרשת"
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const onClickLoginButton = (e) => {
    e.preventDefault()
    if (validateForm()) {
      dispatch(onUserSignInRequest())
      dispatch(onUserSignInClick(state.name, state.passw))
    }
  }

  if (isLoading) {
    return (
      <div>
        <Title text="כניסה למערכת" />
        <Loader show={isLoading} />
      </div>
    )
  }

  return (
    <div className="login-screen">
      <Title text="כניסה למערכת" />
      <form>
        <div className="form-group">
          <label htmlFor="username">שם המשתמש</label>
          <input
            id="username"
            type="text"
            value={state.name}
            placeholder="שם המשתמש"
            onChange={(e) => setValue("name", e.target.value)}
          />
          {errors.name && <div className="error-message">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="password">סיסמא</label>
          <input
            id="password"
            type="password"
            value={state.passw}
            placeholder="סיסמא"
            onChange={(e) => setValue("passw", e.target.value)}
            maxLength={20}
          />
          {errors.passw && <div className="error-message">{errors.passw}</div>}
        </div>
        <div className="buttons-wrapper">
          <Button onClick={onClickLoginButton}>כניסה</Button>
        </div>
        <Message
          show={showMessage}
          isError={true}
          successText=""
          errorText="אנא בדוק את הפרטים ונסה שוב"
          onClick={() => dispatch(closeMessage())}
        />
      </form>
    </div>
  )
}

export default LoginScreen
