import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllExternalStationData,
  localSearchStationsByText,
  localSearchStationsByStations,
} from "../../actions"
import { fetchIfEmpty } from "../../utils"
import { SelectMultiAutocomplete } from "../../components/common"
import InputField from "../../components/common/InputField"
import "./style.css"

class StationsFilterer extends Component {
  componentDidMount() {
    if (this.props.allStationsArr?.length == 0) {
      this.props.fetchAllExternalStationData(this.props.stations)
    }
  }

  updateActiveStations(items, index) {
    this.props.resetCurrentPage()
    this.props.localSearchStationsByStations(items)
  }

  render() {
    const { stationsItemSelectList, searchText, filtererActiveStations } =
      this.props

    return (
      <div className="stations-filterer">
        <InputField
          show={true}
          extraClass="search-input"
          placeholder="חיפוש מספר/שם/כתובת עמדה"
          value={searchText}
          alt="ניתן לחפש עמדה לפי שם סניף, כתובת או מספר."
          icon="magnifying_glass"
          onChange={(e) => {
            this.props.resetCurrentPage()
            this.props.localSearchStationsByText(e.target.value)
          }}
        />
        <SelectMultiAutocomplete
          placeholder="עמדה"
          items={stationsItemSelectList}
          value={filtererActiveStations}
          onChangeHandler={(itm) => this.updateActiveStations(itm)}
          extraClassName="sations-list"
          isMulti={true}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ station }) => {
  const { stationsLocalSearch, allStationsArr, stationsItemSelectList } =
    station
  const { searchText, filtererActiveStations } = stationsLocalSearch

  return {
    allStationsArr,
    stationsItemSelectList,
    searchText,
    filtererActiveStations,
  }
}

export default connect(mapStateToProps, {
  fetchAllExternalStationData,
  localSearchStationsByText,
  localSearchStationsByStations,
})(StationsFilterer)
