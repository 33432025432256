import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Button } from "../../common/Button"
import Message from "../../common/Message"
import Title from "../../common/Title"
import { Spinner } from "../../common/Spinner"
import {
  clearDeliveryDetails,
  fetchAllStationData,
  fetchAllStations,
  onCreateOrderL2L,
  closeMessage,
} from "../../../actions"
import Select from "react-select"
import "./style.scss"

const CreateLockerToLockerScreen = (props) => {
  const [sourceFirstName, setSourceFirstName] = useState("")
  const [sourceLastName, setSourceLastName] = useState("")
  const [sourcePhoneNumber, setSourcePhoneNumber] = useState("")

  const [targetFirstName, setTargetFirstName] = useState("")
  const [targetLastName, setTargetLastName] = useState("")
  const [targetPhoneNumber, setTargetPhoneNumber] = useState("")

  const [validationMsg, setValidationMsg] = useState("")
  const [sourceStationNum, setSourceStationNumber] = useState("")
  const [targetStationNum, setTargetStationNumber] = useState("")
  const [sourceStationId, setSourceStationId] = useState("")
  const [targetStationId, setTargetStationId] = useState("")

  const [targetEmail, setTargetEmail] = useState("")
  const [externalOrderNumber, setExternalOrderNumber] = useState("")

  useEffect(() => {
    if (props.stationsList?.length == 0) {
      props.fetchAllStations()
    }
  }, [])

  useEffect(() => {
    if (props.stationsList.length > 0) {
      props.fetchAllStationData(props.stationsList)
    }
  }, [props.stationsList])

  const onSubmitClick = () => {
    let isValid = validateInput()
    if (isValid) {
      const order = {
        externalOrderNumber: "",
        fromStationNumber: sourceStationNum,
        toStationNumber: targetStationNum,
        fromCustomerFirstName: sourceFirstName,
        fromCustomerLastName: sourceLastName,
        toCustomerFirstName: targetFirstName,
        toCustomerLastName: targetLastName,
        fromMobilePhone: sourcePhoneNumber,
        toMobilePhone: targetPhoneNumber,
        toCustomerEmail: targetEmail,
        externalOrderNumber: externalOrderNumber,
      }
      props.onCreateOrderL2L(order)
    }
  }

  const clearState = () => {
    setSourceFirstName("")
    setSourceLastName("")
    setSourcePhoneNumber("")
    setTargetFirstName("")
    setTargetLastName("")
    setTargetPhoneNumber("")
    setSourceStationNumber("")
    setTargetStationNumber("")
    setSourceStationId("")
    setTargetStationId("")
    setValidationMsg("")
    setTargetEmail("")
    setExternalOrderNumber("")

    props.clearDeliveryDetails()
  }

  const validateInput = () => {
    if (!externalOrderNumber || externalOrderNumber.length < 4) {
      setValidationMsg("הזן מספר הזמנה")
      return false
    }
    if (
      !sourcePhoneNumber ||
      !sourcePhoneNumber.match(/^05\d([-]{0,1})\d{7}$/)
    ) {
      setValidationMsg("מספר טלפון של שולח לא תקין")
      return false
    }
    if (!sourceFirstName || sourceFirstName.length < 2) {
      setValidationMsg("הזן שם של שולח")
      return false
    }

    if (!sourceLastName || sourceLastName.length < 2) {
      setValidationMsg("הזן את שם משפחה של שולח")
      return false
    }
    if (!targetFirstName || targetFirstName.length < 2) {
      setValidationMsg("הזן שם של נמען")
      return false
    }

    if (!targetLastName || targetFirstName.length < 2) {
      setValidationMsg("הזן את שם משפחה של נמען")
      return false
    }
    if (
      !targetPhoneNumber ||
      !targetPhoneNumber.match(/^05\d([-]{0,1})\d{7}$/)
    ) {
      setValidationMsg("מספר טלפון של נמען לא תקין")
      return false
    }

    if (
      targetPhoneNumber &&
      sourcePhoneNumber &&
      sourcePhoneNumber === targetPhoneNumber
    ) {
      setValidationMsg("לא ניתן לשלוח לאותו לקוח")
      return false
    }
    if (!sourceStationNum) {
      setValidationMsg("אנא בחר עמדת מקור")
      return false
    }

    if (!targetStationNum) {
      setValidationMsg("אנא בחר עמדת יעד")
      return false
    }
    setValidationMsg("")
    return true
  }

  const onCloseClick = () => {
    if (!props.isError) {
      clearState()
    }
    props.closeMessage()
  }

  return (
    <div className="locker-to-locker">
      <Title text="יצירת הזמנה" />
      <div className="person-ditails-wrapper order">
        <div className="sub-title">הזמנה </div>
        <div className="input-wrapper">
          <span className="input-title">מספר הזמנה</span>
          <input
            className="input-text"
            value={externalOrderNumber}
            type="text"
            onChange={(e) => {
              setExternalOrderNumber(e.target.value)
            }}
          ></input>
        </div>
      </div>

      <div className="customers-wrapper">
        <div className="cutomer-source">
          <div className="person-ditails-wrapper source">
            <div className="sub-title">פרטי שולח</div>
            <div className="input-wrapper">
              <span className="input-title"> טלפון שולח </span>
              <input
                className="input-text"
                value={sourcePhoneNumber}
                type="number"
                onChange={(e) => {
                  setSourcePhoneNumber(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם פרטי </span>
              <input
                className="input-text"
                value={sourceFirstName}
                maxLength="30"
                onChange={(e) => {
                  setValidationMsg("")
                  setSourceFirstName(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם משפחה </span>
              <input
                className="input-text"
                maxLength="30"
                value={sourceLastName}
                onChange={(e) => {
                  setSourceLastName(e.target.value)
                }}
              ></input>
            </div>
          </div>
          <div className="person-ditails-wrapper ">
            <div className="input-wrapper">
              <span className="input-title sub-title">עמדת מקור </span>{" "}
              <Select
                className="station-selector"
                key={sourceStationNum}
                placeholder="בחר..."
                value={props.stationsItemSelectList.find(
                  (option) => option.value === sourceStationNum
                )}
                options={props.stationsItemSelectList}
                onChange={(e) => {
                  setValidationMsg("")
                  setSourceStationNumber(e.value)
                  setSourceStationId(e.stationId)
                }}
              />
            </div>
          </div>
        </div>
        <div className="cutomer-target">
          <div className="person-ditails-wrapper target">
            <div className="sub-title">פרטי נמען</div>
            <div className="input-wrapper">
              <span className="input-title"> טלפון נמען </span>
              <input
                className="input-text"
                value={targetPhoneNumber}
                type="number"
                onChange={(e) => {
                  setValidationMsg("")
                  setTargetPhoneNumber(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם פרטי </span>
              <input
                className="input-text"
                value={targetFirstName}
                maxLength="30"
                onChange={(e) => {
                  setValidationMsg("")
                  setTargetFirstName(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם משפחה </span>
              <input
                className="input-text"
                maxLength="30"
                value={targetLastName}
                onChange={(e) => {
                  setValidationMsg("")
                  setTargetLastName(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">אימייל</span>
              <input
                className="input-text"
                maxLength="30"
                value={targetEmail}
                onChange={(e) => {
                  setTargetEmail(e.target.value)
                }}
              ></input>
            </div>
          </div>
          <div className="person-ditails-wrapper">
            <div className="input-wrapper">
              <span className="input-title sub-title">עמדת יעד </span>{" "}
              <Select
                className="station-selector"
                key={targetStationNum}
                placeholder="בחר..."
                defaultValue={props.stationsItemSelectList.find(
                  (option) => option.value === targetStationNum
                )}
                options={props.stationsItemSelectList}
                onChange={(e) => {
                  setValidationMsg("")
                  setTargetStationNumber(e.value)
                  setTargetStationId(e.stationId)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="validation-msg">
        {validationMsg ? validationMsg : null}
      </div>

      {props.isLoading ? (
        <Spinner zoom={0.5} />
      ) : (
        <div className="cror-modal-status">
          <Button
            extraClass="cror-modal-button"
            onClick={(e) => onSubmitClick()}
          >
            אישור
          </Button>
          <Button
            extraClass="cror-modal-button"
            onClick={(e) => props.history.goBack()}
          >
            חזרה
          </Button>
        </div>
      )}

      <Message
        show={props.showMessage}
        isError={props.isError}
        successText="ההזמנה נוצרה בהצלחה"
        errorText="יצירת הזמנה נכשלה"
        onClick={(e) => onCloseClick()}
      />
    </div>
  )
}

const mapStateToProp = ({ station, orders, users }) => {
  const { authenticated, userData } = users
  const { stationsList, stationsItemSelectList, selectedStation } = station
  const { isLoading, response, isError, showMessage } = orders

  return {
    authenticated,
    userData,
    stationsList,
    isLoading,
    stationsItemSelectList,
    response,
    isError,
    showMessage,
  }
}

export default connect(mapStateToProp, {
  clearDeliveryDetails,
  fetchAllStations,
  fetchAllStationData,
  onCreateOrderL2L,
  closeMessage,
})(CreateLockerToLockerScreen)
