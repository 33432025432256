import React, { useEffect, useRef } from "react"
import Modal from "../Modal"
import { Button } from "../../common/Button"
import { SmallHeader } from "../../common/SmallHeader"
import "./style.scss"

const NotificationModal = ({ show, type, title, text, onOkClick }) => {
  const modalContainerRef = useRef(null)

  if (!show) {
    return null
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onOkClick()
    }
  }

  return (
    <Modal extraClass="noti-modal-wrap">
      <div
        className="noti-modal-container"
        onKeyDown={handleKeyDown}
        ref={modalContainerRef}
        role="dialog"
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-content"
        tabIndex={-1}
      >
        <SmallHeader id="notification-modal-title" title={title} type={type} />
        <div className="noti-modal-content-wrapper">{text}</div>
        <div className="noti-btn-wrapper">
          <Button autoFocus onClick={onOkClick}>
            אישור
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NotificationModal
