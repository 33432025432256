import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getOrderDetails, onTransferOrder } from "../../../actions"
import Select from "react-select"
import "./style.scss"

const TransferOrderModal = ({
  stations,
  orderId,
  onSaveClick,
  onCloseClick,
}) => {
  const [selectedStation, setSelectedStation] = useState(null)
  const { orderDetails } = useSelector((state) => state.orders)
  const [currentOrder, setcurrentOrder] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [])

  useEffect(() => {
    if (orderDetails) {
      setcurrentOrder(orderDetails)
    }
  }, [orderDetails])

  useEffect(() => {
    if (orderDetails?.stationNumber) {
      const defaultStation = stations.find(
        (item) => item.value === orderDetails?.stationNumber
      )
      setSelectedStation(defaultStation)
    }
  }, [orderDetails?.stationNumber, stations])

  const onConfirmTransferOrder = () => {
    dispatch(onTransferOrder(currentOrder?.id, selectedStation.stationId))
  }

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="order-transfer-modal">
        <div>
          <h2>העברת הזמנה לעמדה אחרת</h2>
          <div className="current-station">
            {orderDetails ? (
              <span>
                עמדה נוכחית:{" "}
                {orderDetails?.stationNumber + " - " + orderDetails?.branchName}
              </span>
            ) : (
              <span>טוען...</span>
            )}
          </div>
          <Select
            value={selectedStation}
            className="station-selector"
            placeholder="עמדה"
            options={stations}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(selectedStation) => {
              setSelectedStation(selectedStation)
            }}
          />
          <div className="btn-holder">
            <button onClick={() => onConfirmTransferOrder()}>שמור</button>
            <button onClick={() => onCloseClick()}>סגור</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransferOrderModal
