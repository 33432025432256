import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import { getOrderDetails, onUpdateOrderStation } from "../../../actions"
import "./style.scss"

const ChangeStationModal = ({ stations, onCloseClick, orderId, show }) => {
  const [selectedStation, setSelectedStation] = useState(null)
  const { orderDetails } = useSelector((state) => state.orders)
  const [currentOrder, setCurrentOrder] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId))
    }
  }, [orderId])

  useEffect(() => {
    if (orderDetails) {
      setCurrentOrder(orderDetails)
      const defaultStation = stations.find(
        (item) => item.value === orderDetails?.stationNumber
      )
      if (defaultStation) {
        setSelectedStation(defaultStation)
      }
    }
  }, [orderDetails, stations])

  const onConfirmChangeStation = () => {
    if (selectedStation && currentOrder) {
      dispatch(
        onUpdateOrderStation(
          currentOrder?.id,
          selectedStation?.value,
          currentOrder?.orderType
        )
      )
    }
  }

  if (!show) {
    return null
  }

  return (
    <div className="stations-modal">
      <div>
        <h2>שינוי עמדה</h2>
        <div className="current-station">
          <span>
            עמדה נוכחית:{" "}
            {orderDetails?.stationNumber + " - " + orderDetails?.branchName}
          </span>
        </div>
        <Select
          value={selectedStation}
          className="station-selector"
          placeholder="עמדה"
          options={stations}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          onChange={(selected) => setSelectedStation(selected)}
        />
        <div className="btn-holder">
          <button onClick={onConfirmChangeStation}>שמור</button>
          <button onClick={onCloseClick}>סגור</button>
        </div>
      </div>
    </div>
  )
}

export default ChangeStationModal
