import {
  ON_UPDATE_MESSAGE_SUCCESS,
  ON_UPDATE_MESSAGE_FAIL,
  GET_STATIONS,
  ON_CLOSE_MESSAGE,
  RESET_ALL_REDUCERS,
  ON_GET_STATIONS_SUCCESS,
  ON_STATIONS_SET_PAGE,
  ON_SELECT_STATION_ID,
  ON_STATION_SHOW_LOADING,
  CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
  FETCH_ALL_STATIONS_DATA,
  ON_STATIONS_FILTER_BY_STATION,
  ON_STATIONS_FILTER_BY_TEXT,
  ON_SET_SELECTED_STATION,
  FETCH_ALL_EXTERNAL_STATIONS_DATA,
} from "../actions/types"

import {
  stations_RecalcFilteredListOutOfLatestState,
  stations_HandleFilteredActiveStationsUpdate,
} from "../utils/"

const INIT_STATE = {
  allStationsArr: [],
  selectedStationId: null,
  stationsList: [],
  isStationUpdated: false,
  isError: false,
  showMessage: false,
  stations: [],
  isLoading: false,
  stationsItemSelectList: [],
  selectedStation: null,
  stationsLocalSearch: {
    searchText: "",
    stationsFilteredListArr: [],
    filtererActiveStations: [],
    page: 1,
  },
}

const EMPTY_STATE = {
  allStationsArr: [],
  selectedStationId: null,
  stationsList: [],
  isStationUpdated: false,
  isError: false,
  showMessage: false,
  stations: [],
  isLoading: false,
  stationsItemSelectList: [],
  stationsLocalSearch: {
    searchText: "",
    stationsFilteredListArr: [],
    filtererActiveStations: [],
    page: 1,
  },
}

const StationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        isStationUpdated: true,
        showMessage: true,
        isError: false,
      }
    case ON_UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        isStationUpdated: false,
        showMessage: true,
        isError: true,
      }
    case GET_STATIONS:
      return {
        ...EMPTY_STATE,
        selectedStation: state.selectedStation,
        stationsList: action.payload,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...EMPTY_STATE,
      }
    case ON_GET_STATIONS_SUCCESS:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stations: action.payload,
        isLoading: false,
      })
    case ON_STATIONS_SET_PAGE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          page: action.payload,
        },
        isLoading: false,
      })
    case ON_SELECT_STATION_ID:
      return {
        ...state,
        selectedStationId: action.payload,
      }
    case ON_STATION_SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case ON_SET_SELECTED_STATION:
      return {
        ...state,
        selectedStation: action.payload,
      }
    case CLEAR_LOCAL_STATIONS_FILTER_SEARCH:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          searchText: "",
          stationsFilteredListArr: [],
          filtererActiveStations: [],
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_TEXT:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          searchText: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case FETCH_ALL_STATIONS_DATA:
      return handleAllStationsData(state, action.payload)
    case FETCH_ALL_EXTERNAL_STATIONS_DATA:
      return handleAllExternalStationsData(state, action.payload)
    case ON_STATIONS_FILTER_BY_STATION:
      return stations_HandleFilteredActiveStationsUpdate(state, action.payload)
    default:
      return state
  }
}

const handleAllStationsData = (state, allStationsArr) => {
  const stationsItemSelectList = allStationsArr

  const sortedStationsNames = stationsItemSelectList.sort(function (a, b) {
    return a.value - b.value
  })

  const formattedStationsNames = allStationsArr.map(
    ({ stationNumber, branchName }) => `${stationNumber} - ${branchName}`
  )

  const stationSelectList = allStationsArr.map(
    ({ stationNumber, branchName, stationId, branchDisplayName }) => ({
      value: stationNumber,
      stationId: stationId,
      label:
        stationNumber +
        " - " +
        (branchDisplayName ? branchDisplayName : branchName),
    })
  )

  return {
    ...state,
    unauthorized: false,
    allStationsArr,
    formattedStationsNames,
    stationsItemSelectList,
    sortedStationsNames,
    stationSelectList,
  }
}

const handleAllExternalStationsData = (state, allStationsArr) => {
  const stationsItemSelectList = allStationsArr.map(
    ({ stationNumber, branchName, stationId, branchDisplayName }) => ({
      value: stationNumber,
      stationId: stationId,
      label: branchName
        ? stationNumber + " - " + branchName
        : stationNumber + " - " + branchDisplayName,
    })
  )
  const sortedStationsNames = stationsItemSelectList.sort(function (a, b) {
    return a.value - b.value
  })

  const formattedStationsNames = allStationsArr.map(
    ({ stationNumber, branchName }) => `${stationNumber} - ${branchName}`
  )

  const stationSelectList = allStationsArr.map(
    ({ stationNumber, branchName, stationId, branchDisplayName }) => ({
      value: stationNumber,
      stationId: stationId,
      label:
        stationNumber +
        " - " +
        (branchDisplayName ? branchDisplayName : branchName),
    })
  )

  return {
    ...state,
    unauthorized: false,
    allStationsArr,
    formattedStationsNames,
    stationsItemSelectList,
    sortedStationsNames,
    stationSelectList,
  }
}

export default StationReducer
