import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"

import {
  onStartLoading,
  onFetchL2LExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  getOrderDetails,
  updateOrderStatus,
  clearOrderUpdate,
  updateOrderStatusByAccount,
  updateOrderStatusAndExtentionDate,
  clearOrderIdsForPrint,
  clearTransferOrder,
} from "../../../actions"

import "./style.scss"
import L2LOrdersList from "./L2LOrdersList"
import OrderHistoryModal from "../../common/OrderHistoryModal"
import { getFormatedDate } from "../../../utils"
import YesNoModal from "../../common/YesNoModal"
import OrderDelayModal from "../../common/OrderDelayModal"
import NotificationModal from "../../common/NotificationModal"
import TransferOrderModal from "../../common/TransferOrderModal"

const L2LOrdersListScreen = ({ history }) => {
  const dispatch = useDispatch()
  const {
    authenticated,
    isLoading,
    orderList,
    orderDetails,
    ordersStatusesArr,
    orderStatusUpdateSuccess,
    orderStatusUpdateByAccountSuccess,
    orderStatusUpdateByAccountFail,
    stationsList,
    transferOrderSuccess,
    transferOrderFail,
  } = useSelector((state) => ({
    authenticated: state.users.authenticated,
    isLoading: state.orders.isLoading,
    orderList: state.orders.orderList,
    orderDetails: state.orders.orderDetails,
    stationsList: state.station.stationsList,
    ordersStatusesArr: state.orderStatuses.ordersStatusesArr,
    orderStatusUpdateSuccess: state.orders.orderStatusUpdateSuccess,
    orderStatusUpdateByAccountSuccess:
      state.orders.orderStatusUpdateByAccountSuccess,
    orderStatusUpdateByAccountFail: state.orders.orderStatusUpdateByAccountFail,
    transferOrderSuccess: state.orders.transferOrderSuccess,
    transferOrderFail: state.orders.transferOrderFail,
  }))

  const [state, setState] = useState({
    orderExternalNumbers: null,
    isShowHistory: false,
    isShowPDF: false,
    packageNumber: null,
    showCancelOrderModal: false,
    showOrderDelayModal: false,
    selectedOrder: null,
    currentOrderInLockerDate: null,
    isShowTransferOrder: false,
  })

  useEffect(() => {
    if (!authenticated) {
      history.push("/")
    } else {
      dispatch(onStartLoading())
      dispatch(fetchOrderStatuses())
      dispatch(onFetchL2LExistOrders())
    }
  }, [authenticated, history, dispatch])

  useEffect(() => {
    if (orderStatusUpdateSuccess) {
      dispatch(onFetchL2LExistOrders())
      dispatch(clearOrderUpdate())
    }
  }, [orderStatusUpdateSuccess, dispatch])

  const refreshTable = () => {
    dispatch(onFetchL2LExistOrders())
  }

  const onClickOrderHistory = async (order) => {
    const currentOrder = orderList.find(
      (o) => o.orderNumber === order.original.orderNumber
    )
    await dispatch(getOrderDetails(currentOrder.id))
    setState((prev) => ({ ...prev, isShowHistory: true }))
  }

  const onListIconClick = (order) => {
    const currentNumbers = order.original.externalOrderNumber.split(",")
    setState((prev) => ({ ...prev, orderExternalNumbers: currentNumbers }))
  }

  const confirmCancelOrder = () => {
    dispatch(updateOrderStatus(state.selectedOrder.id, 6))
    setState((prev) => ({ ...prev, showCancelOrderModal: false }))
  }

  const extendPackageClick = (order) => {
    const currentOrder = orderList.find((o) => o.id === order.id)
    setState((prev) => ({
      ...prev,
      showOrderDelayModal: true,
      selectedOrder: order,
      currentOrderInLockerDate: currentOrder.inLockerExtentionDate,
    }))
  }

  const updateInLockerDate = (selectedDate) => {
    const dateWithTime = new Date(selectedDate)
    dateWithTime.setHours(23, 0, 0)
    const unixTimestamp = Math.floor(dateWithTime.getTime() / 1000)
    dispatch(
      updateOrderStatusByAccount(state.selectedOrder.id, 4, unixTimestamp)
    )
  }

  const onCloseMsg = () => {
    dispatch(clearOrderUpdate())
    refreshTable()
    setState((prev) => ({
      ...prev,
      showOrderDelayModal: false,
    }))
  }

  const onTransferOrderClick = (order) => {
    setState((prev) => ({
      ...prev,
      isShowTransferOrder: true,
      selectedOrder: order,
    }))
  }

  const onCloseTransferOrderMsg = () => {
    dispatch(clearTransferOrder())
    setState({ isShowTransferOrder: false })
  }

  if (isLoading) {
    return (
      <div>
        <Title text="רשימת הזמנות" />
        <Loader show={isLoading} />
      </div>
    )
  }

  return (
    <div>
      <Title text="רשימת הזמנות" />
      {ordersStatusesArr?.length > 0 ? (
        <L2LOrdersList
          onHistoryClick={onClickOrderHistory}
          onListIconClick={onListIconClick}
          refreshTable={refreshTable}
          extendPackageClick={extendPackageClick}
          onTransferOrderClick={onTransferOrderClick}
        />
      ) : (
        "Loading..."
      )}

      <OrderHistoryModal
        isShow={state.isShowHistory}
        order={orderDetails}
        setViewHistory={() =>
          setState((prev) => ({ ...prev, isShowHistory: false }))
        }
        statuses={ordersStatusesArr}
        getFormatedDate={getFormatedDate}
      />

      <OrderDelayModal
        key={state.selectedOrder?.id}
        isOpen={state.showOrderDelayModal}
        isClose={() =>
          setState((prev) => ({ ...prev, showOrderDelayModal: false }))
        }
        submitDate={updateInLockerDate}
        inLockerDate={state.currentOrderInLockerDate}
      />

      {state.isShowTransferOrder ? (
        <TransferOrderModal
          stations={stationsList}
          orderId={state.selectedOrder?.id}
          onCloseClick={() => setState({ isShowTransferOrder: false })}
        />
      ) : null}

      <YesNoModal
        show={state.showCancelOrderModal}
        title={"מחיקת הזמנה"}
        text={"?האם אתה בטוח שברצונך לבטל הזמנה זו"}
        onYesClick={confirmCancelOrder}
        onNoClick={() =>
          setState((prev) => ({ ...prev, showCancelOrderModal: false }))
        }
      />

      <NotificationModal
        show={orderStatusUpdateByAccountSuccess}
        title={" עדכון הזמנה"}
        text={"הזמנה עודכנה בהצלחה"}
        onOkClick={onCloseMsg}
      />

      <NotificationModal
        extraClass="msg-error"
        show={orderStatusUpdateByAccountFail}
        title={" עדכון הזמנה"}
        text={"עדכון הזמנה נכשל"}
        onOkClick={onCloseMsg}
      />

      <NotificationModal
        extraClass="success"
        show={transferOrderSuccess}
        text={"בקשה להעברת הזמנה לעמדה אחרת התקבלה בהצלחה"}
        onOkClick={onCloseTransferOrderMsg}
      />
      <NotificationModal
        type={"error"}
        show={transferOrderFail}
        text={" .שגיא, בקשה לא התקבלה "}
        onOkClick={onCloseTransferOrderMsg}
      />
    </div>
  )
}

export default L2LOrdersListScreen
