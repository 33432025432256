import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import TableL2LContainer from "../../../common/TableL2LContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import history_icon from "../../../../assets/svg/history_icon.svg"
import list_icon from "../../../../assets/svg/list_icon.svg"
import {
  addToPrintList,
  removeFromPrintList,
} from "../../../../actions/BarcodeActions"

import "./style.scss"

const L2LOrdersList = ({
  orderList,
  selectedStation,
  ordersStatusesArr,
  userData,
  isLoading,
  onHistoryClick,
  onListIconClick,
  onCancelOrderClick,
  extendPackageClick,
  refreshTable,
  printAllSelectedBarcodes,
  onTransferOrderClick,
}) => {
  const [filterValue, setFilterValue] = useState("")
  const [hiddenColumns] = useState(["orderStatusId"])

  const formattedOrders = useMemo(() => {
    if (!orderList) return []

    return orderList.map((order) => ({
      ...order,
      mobilePhone: setEmtyIfNull(order.mobilePhone),
      firstName: setEmtyIfNull(order.firstName),
      lastName: setEmtyIfNull(order.lastName),
      email: setEmtyIfNull(order.email),
      orderStatus: ordersStatusesArr[order.orderStatus],
      orderDate: getFormatedDate(order.orderDate),
      inLockerExtentionDate: getFormatedDate(order.inLockerExtentionDate),
      orderStatusId: order.orderStatus,
      customerSource:
        order.customerSource?.firstName + " " + order.customerSource?.lastName,
      customerTarget:
        order.customerTarget?.firstName + " " + order.customerTarget?.lastName,
    }))
  }, [orderList, ordersStatusesArr])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "תאריך",
        accessor: "orderDate",
      },
      {
        Header: "מספר הזמנה",
        accessor: "orderNumber",
      },
      {
        Header: "מספר חבילה",
        accessor: "externalOrderNumber",
      },
      {
        Header: "סטטוס",
        accessor: "orderStatus",
        Filter: SelectColumnFilter,
        filter: filterValue,
      },

      {
        Header: "שם שולח",
        accessor: "customerSourceFullName",
      },
      {
        Header: "עמדת מקור",
        accessor: "stationSourceName",
      },
      {
        Header: "שם נמען",
        accessor: "customerTargetFullName",
      },
      {
        Header: "עמדת יעד",
        accessor: "stationTargetName",
      },
      {
        Header: "אימייל נמען",
        accessor: "customerTargetEmail",
      },
      {
        Header: "נוצר על ידי",
        accessor: "createdByName",
      },
      {
        Header: "תאריך הארכה",
        accessor: "inLockerExtentionDate",
      },
    ]

    const actionColumns = [
      {
        Header: () => null,
        id: "key",
        Cell: ({ row }) => (
          <img
            className="order-edit"
            alt="edit_button"
            src={history_icon}
            onClick={() => onHistoryClick(row)}
          />
        ),
      },

      {
        Header: () => null,
        id: "cancel_order",
        Cell: ({ row }) => {
          const { orderStatusId } = row.original
          return (
            (orderStatusId === 19 || orderStatusId === 18) && (
              <i
                className="fas fa-trash order-edit"
                alt="cancel_order_button"
                onClick={() => onCancelOrderClick(row.original)}
              ></i>
            )
          )
        },
      },
      {
        Header: () => null,
        id: "delay",
        Cell: ({ row }) => {
          const { orderStatusId } = row.original
          return (
            [3, 4, 13].includes(orderStatusId) && (
              <i
                className="fa fa-calendar"
                alt="delay_button"
                onClick={() => extendPackageClick(row.original)}
              ></i>
            )
          )
        },
      },

      {
        Header: () => null,
        id: "transfer_order_l2l",
        Cell: ({ row }) => {
          const { orderStatusId } = row.original
          return (
            (orderStatusId === 4 || orderStatusId === 13) && (
              <i
                className="fa fa-random"
                aria-hidden="true"
                alt="cancel_order_button"
                onClick={() => onTransferOrderClick(row.original)}
              ></i>
            )
          )
        },
      },
    ]

    return [...actionColumns, ...baseColumns]
  }, [filterValue, userData?.accountType])

  const clearFilterAndRefreshTable = () => {
    setFilterValue("")
    refreshTable()
  }

  return (
    <div className="order-list-l2l-wrapper">
      <Container className="order-list-l2l">
        <TableL2LContainer
          columns={columns}
          hiddenColumns={hiddenColumns}
          data={formattedOrders}
          refreshTable={clearFilterAndRefreshTable}
          filterValue={filterValue}
          printAllSelectedBarcodes={printAllSelectedBarcodes}
          resetFilterValue={() => setFilterValue("")}
          isLoading={isLoading}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({
  orders,
  orderStatuses,
  users,
  station,
  barcode,
}) => ({
  orderList: orders.orderList,
  ordersStatusesArr: orderStatuses.ordersStatusesArr,
  userData: users.userData,
  selectedStation: station.selectedStation,
  isLoading: barcode.isLoading,
})

export default connect(mapStateToProps, {
  addToPrintList,
  removeFromPrintList,
})(L2LOrdersList)
