import React from "react"
import "./style.scss"

const MenuItem = ({ show, text, onClick, ...props }) => {
  if (!show) {
    return null
  }

  return (
    <div className="menu-item" onClick={onClick}>
      {text}
    </div>
  )
}

export default MenuItem
