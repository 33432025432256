import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import MenuItem from "./MenuItem"
import OpenMenuButton from "./OpenMenuButton"
import "./style.scss"

const Menu = ({ userData }) => {
  const [isActive, setIsActive] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { role, orderTypeGroup, externalMiniBoTypeId } = userData || {}

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("menu")
      let targetElement = event.target
      do {
        if (targetElement == menu) {
          return
        }
        targetElement = targetElement.parentNode
      } while (targetElement)
      setIsActive(false)
    }
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const isShowMenu = () => {
    const currentPath = location.pathname
    return currentPath !== "/" && currentPath !== "/StationSelectScreen"
  }

  const handleMenuItemClick = (path) => {
    history.push(path)
    setIsActive(false)
  }

  if (!isShowMenu()) {
    return null
  }

  return (
    <div className="menu-wrapper" id="menu">
      <OpenMenuButton isActive={isActive} onClickMe={(e) => setIsActive(e)} />
      <div className="menu-items-wrapper">
        {externalMiniBoTypeId != "3" && (
          <MenuItem
            show={isActive}
            text="הזמנות"
            onClick={() => handleMenuItemClick("/OrdersListScreen")}
          />
        )}
        {externalMiniBoTypeId != "3" && (
          <MenuItem
            show={isActive}
            text="יצירת הזמנה"
            onClick={() => handleMenuItemClick("/CreateNewOrderScreen")}
          />
        )}
        {externalMiniBoTypeId != "3" && (
          <MenuItem
            show={isActive}
            text="יצירת הזמנה להחזרה"
            onClick={() => handleMenuItemClick("/CreateReturnOrderScreen")}
          />
        )}
        {externalMiniBoTypeId == "3" && (
          <MenuItem
            show={isActive}
            text="הזמנות L2L"
            onClick={() => handleMenuItemClick("/L2LOrdersListScreen")}
          />
        )}
        {externalMiniBoTypeId == "3" && (
          <MenuItem
            show={isActive}
            text="יצירת הזמנה L2L"
            onClick={() => handleMenuItemClick("/CreateLockerToLockerScreen")}
          />
        )}

        <MenuItem
          show={isActive}
          text="עמדות"
          onClick={(e) => {
            history.push("/StationsScreen")
          }}
        />
        <MenuItem
          show={isActive}
          text="סריקה"
          onClick={(e) => {
            history.push("/ScanOrderScreen")
          }}
        />

        {/* <MenuItem
          show={isActive}
          text="SMS שנכשלו"
          onClick={() => handleMenuItemClick("/FailedSMSScreen")}
        /> */}

        <MenuItem
          show={isActive}
          text="התנתק"
          onClick={() => handleMenuItemClick("/")}
        />
      </div>
    </div>
  )
}

export default Menu
